<template>
  <div>
    <CCard>
      <CCardHeader>
        <h1>{{ user.name }} {{ user.surname }}</h1>
        <h2>Sesión: {{ sessionDateFormated }}</h2>
      </CCardHeader>
      <CCardBody>
        <CRow style="text-align: center">
          <CCol>
            <div>
              <h2>{{ totalSteps }}</h2>
              <h2>Pasos dados</h2>
            </div>
          </CCol>
          <CCol>
            <h2>{{ totalWeight }}</h2>
            <h2>kg cargados</h2>
          </CCol>
          <CCol>
            <h2>{{ stepsPerHour }}</h2>
            <h2>Pasos por hora</h2>
          </CCol>
        </CRow>
      </CCardBody>

      <hr>
      <div class="circular-chart" v-if="this.seriesData">
        <apexchart
          width="600"
          type="pie"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
      <hr>
      
      <CCardBody v-if="checkChallengeData">
        <h3>Retos</h3>
        <div v-for="challenge of userChallenges" :key="challenge.id_user_challenge">
          <h4 v-if="challenge.challenge_type===MINIMUM_STEPS_CHALLENGE">Dar {{challenge.challenge_limit}} {{challenge.challenge_title}}</h4>
          <h4 v-else-if="challenge.challenge_type===MAXIMUM_WEIGHT_CHALLENGE">Levantar {{challenge.challenge_limit}} kg de {{challenge.challenge_title}}</h4>
          <h4 v-else-if="challenge.challenge_type===MINIMUM_WEIGHT_CHALLENGE">Levantar {{challenge.challenge_limit}} kg de {{challenge.challenge_title}}</h4>
          <CRow>
            <CCol sm="11">
              <div>
                <CProgress class="mb-3">
                  <CProgressBar v-if="calculatePercentageProgressBarChallenges(challenge.challenge_type,challenge.challenge_limit)<100" :value="calculatePercentageProgressBarChallenges(challenge.challenge_type,challenge.challenge_limit)">{{getChallengeProgress(challenge.id_user_challenge)}}</CProgressBar>
                  <CProgressBar v-else-if="calculatePercentageProgressBarChallenges(challenge.challenge_type,challenge.challenge_limit)>=100" color="success" :value="calculatePercentageProgressBarChallenges(challenge.challenge_type,challenge.challenge_limit)">{{getChallengeProgress(challenge.id_user_challenge)}}</CProgressBar>
                </CProgress>
              </div>
            </CCol>
            <CCol><div>{{challenge.challenge_limit}}</div>
            </CCol>
          </CRow>
        </div>      
      </CCardBody>
      
      <hr>
      
      <CCardBody v-if="checkAlertData">
        <h3>Alertas</h3>
        <div v-for="alert of userAlerts" :key="alert.id_user_alert">

          <h4 v-if="alert.alert_type===MINIMUM_WEIGHT_ALERT">Levantar {{alert.alert_limit}} kg de {{alert.alert_title}}</h4>
          <h4 v-else-if="alert.alert_type===MAXIMUM_WEIGHT_ALERT">Levantar {{alert.alert_limit}} kg de {{alert.alert_title}}</h4>
          <h4 v-else-if="alert.alert_type===MAXIMUM_STEPS_ALERT">Dar {{alert.alert_limit}} {{alert.alert_title}}</h4>
          <CRow>
            <CCol sm="11">
              <div>
                <CProgress class="mb-3" >
                  <CProgressBar v-if="calculatePercentageProgressBarAlerts(alert.alert_type,alert.alert_limit)<100" :value="calculatePercentageProgressBarAlerts(alert.alert_type,alert.alert_limit)">{{getAlertProgress(alert.id_user_alert)}}</CProgressBar>
                  <CProgressBar v-else-if="calculatePercentageProgressBarAlerts(alert.alert_type,alert.alert_limit)>=100" color="success" :value="calculatePercentageProgressBarAlerts(alert.alert_type,alert.alert_limit)">{{getAlertProgress(alert.id_user_alert)}}</CProgressBar>
                </CProgress>
              </div>
            </CCol>
            <CCol><div>{{alert.alert_limit}}</div>
            </CCol>
          </CRow>
        </div>
      </CCardBody>
      
      <hr>

      <CCardBody>
        <h3>Gráficas</h3>
        <h4>Pasos por hora a lo largo del día</h4>
        <div>
          <div>
            <CChartBar
              style="height: 400px"
              :datasets="[
                {
                  data: dataStepsChartY,
                  backgroundColor: '#0000FF',
                  label: 'Pasos',
                },
              ]"
              :labels="dataChartX"
              :options="{ maintainAspectRatio: false }"
            />
          </div>
        </div>
        <h4>Peso levantado por horas a lo largo del día</h4>
        <div>
          <div>
            <CChartBar
              style="height: 400px"
              :datasets="[
                {
                  data: dataWeightChartY,
                  backgroundColor: '#0000FF',
                  label: 'Peso',
                },
              ]"
              :labels="dataChartX"
              :options="{ maintainAspectRatio: false }"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import {
  getSessionDataPerSessionID,
  getSessionsDataPerUserID,
  checkUser,
  getUserDataPerID,
  getUserChallenges,
  getUserAlerts,
  changeDateFormat,
  getUserLimit,
} from "../../utils/utils";
import "core-js/stable";
import "regenerator-runtime/runtime";
import VueApexCharts from "vue-apexcharts";
export default {
  name: "SessionDetails",
  data() {
    return {
      user: "",
      sessions: [],
      userChallenges: [],
      userAlerts: [],
      URL_user_id: null,
      URL_session_id: null,
      totalWeight: -1,
      totalSteps: -1,
      maximumWeight: 0,
      stepsPerHour: 0,
      sessionDate: "",
      sessionDateFormated: "",
      progress: 0,
      checkAlertData: false,
      checkChallengeData: false,
      MINIMUM_WEIGHT_ALERT: 1,
      MAXIMUM_WEIGHT_ALERT: 2,
      MAXIMUM_STEPS_ALERT: 3,
      MINIMUM_STEPS_CHALLENGE: 1,
      MAXIMUM_WEIGHT_CHALLENGE: 2,
      MINIMUM_WEIGHT_CHALLENGE: 3,
      dataChartX: [
        "00-01",
        "01-02",
        "02-03",
        "03-04",
        "04-05",
        "05-06",
        "06-07",
        "07-08",
        "08-09",
        "09-10",
        "10-11",
        "11-12",
        "12-13",
        "13-14",
        "14-15",
        "15-16",
        "16-17",
        "17-18",
        "18-19",
        "19-20",
        "20-21",
        "21-22",
        "22-23",
        "23-00",
      ],
      dataStepsChartY: [],
      dataWeightChartY: [],
      minimumChartWeight: 0,
      maximumChartWeight: 0,
      seriesData: false,
      options: {
        labels: [
          "Peso permitido",
          "Peso por encima de lo permitido",
          "Peso por debajo de lo permitido",
        ],
        colors: ["#00FF7F", "#FF0000", "#6A5ACD"],
        width: "100%",
        height: "auto",
      },
      series: [0, 0, 0],
    };
  },
  mounted() {
    this.receiveData();
  },
  created() {
    if (!checkUser()) return;
  },
  methods: {
    async receiveData() {
      await this.getUserID();
      await this.getUser();
      await this.getPieChart();
      await this.getSessionDate();
      await this.getSession();
      await this.getUserChallengesData();
      await this.getUserAlertsData();
      await this.checkData();
    },
    async getUserID() {
      if (!this.$route.params.id == this.URL_user_id) return;
      this.URL_user_id = this.$route.params.id;
      this.URL_session_id = this.$route.params.id_session;
    },
    async getUser() {
      let userData = getUserDataPerID(this.URL_user_id);
      await userData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.user = result.user;
      });
    },
    async getPieChart() {
      let chartData = getUserLimit(this.URL_user_id);
      await chartData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.minimumChartWeight = result.user_limit.min_limit;
        this.maximumChartWeight = result.user_limit.max_limit;
        
        this.options.labels[0]=this.options.labels[0]+" ("+ this.minimumChartWeight + " - " + this.maximumChartWeight + " kg)";
        this.options.labels[1]=this.options.labels[1]+" ("+ this.maximumChartWeight + " kg)";
        this.options.labels[2]=this.options.labels[2]+" ("+ this.minimumChartWeight + " kg)";
      });
    },
    async getSessionDate() {
      let sessionData = getSessionsDataPerUserID(this.URL_user_id);
      await sessionData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        result.session.forEach((session) => {
          if (session.id_session == this.URL_session_id)
            this.sessionDate = session.date;
        });
        this.sessionDateFormated = changeDateFormat(this.sessionDate);
      });
    },
    /**
     * Función para recibir los datos de la sesion actual
     */
    async getSession() {
      let sessionData = getSessionDataPerSessionID(
        this.$route.params.id,
        this.$route.params.id_session
      );
      await sessionData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        result.session.forEach((element) => {
          this.totalSteps += element.steps;
          this.totalWeight += element.weight;
        });

        this.sessions = result.session;
        if (this.totalSteps >= 0) {
          this.totalSteps++;
          this.totalWeight++;
        }
        this.totalWeight = Math.round(this.totalWeight * 100) / 100;
        this.initData();
      });
    },
    async initData() {
      if (this.sessions.length < 1) {
        return;
      }
      let sessionHours = 0;
      let date;
      let getHour = false;
      this.maximumWeight = this.sessions[0].weight;
      this.sessions.forEach((session) => {
        if (this.maximumWeight < session.weight)
          this.maximumWeight = session.weight;
      });

      for (let i = 0; i < 24; i++) {
        getHour = false;
        this.sessions.forEach((session) => {
          date = new Date(session.timestamp * 1000);
          if (date.getHours() == i && getHour == false) {
            sessionHours++;
            getHour = true;
          }
        });
      }
      this.stepsPerHour =
        Math.round((this.totalSteps / sessionHours) * 100) / 100;
      await this.getPieChartData();
      await this.getChartData();
    },
    async getUserChallengesData() {
      let challengeData = getUserChallenges(this.URL_user_id);
      await challengeData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.userChallenges = result.challenges;
      });
    },
    /**
     * Función en la que se obtienen las alertas del usuario actual
     *
     */
    async getUserAlertsData() {
      let alertData = getUserAlerts(this.URL_user_id);
      await alertData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.userAlerts = result.alerts;
      });
    },
    async getPieChartData() {
      let cont=0
      this.sessions.forEach((element) => {
        cont++
        if (element.weight<this.minimumChartWeight){
          this.series[2]+=element.steps;
        }else if (element.weight>=this.minimumChartWeight && element.weight<=this.maximumChartWeight){
          this.series[0]+=element.steps;
        }else if (element.weight>this.maximumChartWeight){
          this.series[1]+=element.steps;
        }
      });
      this.seriesData=true;
    },
    async getChartData() {
      let dataStepsY = [];
      let dataWeightY = [];
      let stepsPerHourChart = 0;
      let weightPerHourChart = 0;
      let date;
      for (let i=0;i<24;i++){
        stepsPerHourChart=0;
        weightPerHourChart=0;
        this.sessions.forEach(session => {
          date= new Date(session.timestamp*1000)
          if (date.getHours()==i) {
            stepsPerHourChart=stepsPerHourChart+session.steps;
            weightPerHourChart=weightPerHourChart+session.weight;
          }
        });
        dataStepsY[i] = stepsPerHourChart;
        dataWeightY[i] = weightPerHourChart;
      }
      this.dataStepsChartY = dataStepsY;
      this.dataWeightChartY = dataWeightY;
    },
    /**
     * Función para calcular el porcentaje de la barra de progreso dado
     * el progreso actual y el límite
     *
     * @returns {number} El porcentaje de progreso
     */
    calculatePercentageProgressBarChallenges(type, limit) {
      let challengeProgress;
      switch (type) {
        case this.MINIMUM_STEPS_CHALLENGE:
          challengeProgress = this.totalSteps;
          break;
        case this.MAXIMUM_WEIGHT_CHALLENGE: challengeProgress=this.maximumWeight;
          break;
        case this.MINIMUM_WEIGHT_CHALLENGE: challengeProgress=this.maximumWeight;
          break;
      }
      
      let percentage =(challengeProgress/limit)*100;
      return percentage;
    },   
    calculatePercentageProgressBarAlerts(type,limit){ 
      let alertProgress;
      switch (type){
        case this.MINIMUM_WEIGHT_ALERT:alertProgress=this.maximumWeight;
          break;
        case this.MAXIMUM_WEIGHT_ALERT: alertProgress=this.maximumWeight;
          break;
        case this.MAXIMUM_STEPS_ALERT: alertProgress=this.totalSteps;
          break;
      }
      let percentage =(alertProgress/limit)*100;
      return percentage;
    },
    getChallengeProgress(id_user_challenge){
      let challengeProgress=0;
      this.userChallenges.forEach(challenge => {
        if (challenge.id_user_challenge==id_user_challenge){
          switch (challenge.challenge_type){
            case this.MINIMUM_STEPS_CHALLENGE: challengeProgress=this.totalSteps;
              break;
            case this.MAXIMUM_WEIGHT_CHALLENGE: challengeProgress=this.maximumWeight;
              break;
            case this.MINIMUM_WEIGHT_CHALLENGE: challengeProgress=this.maximumWeight;
              break;
          }
        }
      });
      return challengeProgress;
    },
    getAlertProgress(id_user_alert){
      let alertProgress=0;
      this.userAlerts.forEach(alert => {
        if (alert.id_user_alert==id_user_alert){
          switch (alert.alert_type){
            case this.MINIMUM_WEIGHT_ALERT:alertProgress=this.maximumWeight;
              break;
            case this.MAXIMUM_WEIGHT_ALERT: alertProgress=this.maximumWeight;
              break;
            case this.MAXIMUM_STEPS_ALERT: alertProgress=this.totalSteps;
              break;
          }
        }
      });
      return alertProgress;
    },
    async checkData(){
      if(this.totalSteps!=-1 && this.totalWeight!=-1){
        this.checkAlertData=true;
        this.checkChallengeData=true;
      }else{ 
        this.totalSteps=0;
        this.totalWeight=0;
        await this.initData();
      }
      let percentage = (alertProgress / limit) * 100;
      return percentage;
    },
    getChallengeProgress(id_user_challenge) {
      let challengeProgress = 0;
      this.userChallenges.forEach((challenge) => {
        if (challenge.id_user_challenge == id_user_challenge) {
          switch (challenge.challenge_type) {
            case this.MINIMUM_STEPS_CHALLENGE:
              challengeProgress = this.totalSteps;
              break;
            case this.MAXIMUM_WEIGHT_CHALLENGE:
              challengeProgress = this.maximumWeight;
              break;
            case this.MINIMUM_WEIGHT_CHALLENGE:
              challengeProgress = this.maximumWeight;
              break;
          }
        }
      });
      return challengeProgress;
    },
    getAlertProgress(id_user_alert) {
      let alertProgress = 0;
      this.userAlerts.forEach((alert) => {
        if (alert.id_user_alert == id_user_alert) {
          switch (alert.alert_type) {
            case this.MINIMUM_WEIGHT_ALERT:
              alertProgress = this.maximumWeight;
              break;
            case this.MAXIMUM_WEIGHT_ALERT:
              alertProgress = this.maximumWeight;
              break;
            case this.MAXIMUM_STEPS_ALERT:
              alertProgress = this.totalSteps;
              break;
          }
        }
      });
      return alertProgress;
    },
    async checkData() {
      if (this.totalSteps != -1 && this.totalWeight != -1) {
        this.checkAlertData = true;
        this.checkChallengeData = true;
      } else {
        this.totalSteps = 0;
        this.totalWeight = 0;
        await this.initData();
      }
    },
  },
};
</script>
<style scoped>
.circular-chart {
  margin: 0 auto;
}
</style>